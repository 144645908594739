import { Menu, MenuItem, Button, Intent } from "@blueprintjs/core";
import { PopoverPosition } from "@blueprintjs/core/lib/esm/components";
import { Popover2 } from "@blueprintjs/popover2";
import React from "react";
import { useNavigate, Outlet } from "react-router-dom";
import styles from "./layout.module.scss";

const Layout = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.headerz}>
        <Popover2
          content={
            <Menu>
              <MenuItem
                icon="home"
                text="Home"
                onClick={() => navigate("/rnd")}
              />
              <MenuItem
                icon="learning"
                text="Basic Exercises"
                onClick={() => navigate("basics")}
              />
              <MenuItem
                icon="heat-grid"
                text="Tic Tac Toe"
                onClick={() => navigate("ttt")}
              />
              <MenuItem
                icon="nest"
                text="Snake"
                onClick={() => navigate("snake")}
              />
              <MenuItem
                icon="error"
                text="Going Nowhere!"
                onClick={() => navigate("nowhere")}
              />
            </Menu>
          }
          position={PopoverPosition.BOTTOM}
        >
          <Button intent={Intent.PRIMARY} rightIcon="arrow-down">
            Select Project
          </Button>
        </Popover2>
      </div>
      <Outlet />
    </div>
  );
};

export default Layout;

import React, { FormEvent, useState } from "react";
import styles from "./Basics.module.scss";

const GreetForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const submitForm = (e: FormEvent) => {
    if (!firstName || !lastName) alert("Please enter a first and last name!");
    else alert(`Hello, ${firstName} ${lastName}!`);
    e.preventDefault();
  };

  return (
    <form className={styles.greetForm} onSubmit={submitForm}>
      <div>
        First Name:
        <input
          id={"fname"}
          value={firstName}
          onChange={(e) => setFirstName(e.currentTarget.value)}
        />
      </div>
      <div>
        Last Name:
        <input
          id={"lname"}
          value={lastName}
          onChange={(e) => setLastName(e.currentTarget.value)}
        />
      </div>
      <div>
        <button formAction="submit">Greet Me!</button>
      </div>
    </form>
  );
};

export default GreetForm;

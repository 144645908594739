import React from "react";

type TestData = {
  id: number;
  name: string;
};

const NoContent = () => {
  const [data, setData] = React.useState<TestData[]>();

  React.useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const resp = await fetch("https://devmc-api.azurewebsites.net/TestData");
    // if (!resp) setData("Error");
    // else {
    const data = (await resp.json()) as TestData[];
    setData(data);
    // }
  };

  return <div>{data && data.map((s) => <div key={s.id}>{s.name}</div>)}</div>;
  //return <div>No content available!</div>;
};

export default NoContent;

import React from "react";
import styles from "./Basics.module.scss";

type Props = {
  number: number;
  description: string;
  children: React.ReactNode;
};

const Excercise = (props: Props) => {
  return (
    <div className={styles.exerciseContainer}>
      <div className={styles.excerciseHeader}>
        Excercise #{props.number} - {props.description}
      </div>
      {props.children}
    </div>
  );
};

export default Excercise;

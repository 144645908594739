import React, { useState } from "react";
import styles from "./splash.module.scss";
import FunForm from "./FunForm";

const Splash = () => {
  const [funEnabled, setFunEnabled] = useState(false);

  return (
    <>
      <div className={styles.backGround}>
        <div className={styles.title}>
          <button onClick={() => setFunEnabled(true)}>devmc.com</button>
        </div>
        {funEnabled && <FunForm />}
      </div>
    </>
  );
};

export default Splash;

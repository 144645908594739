import React, { FormEvent, useState } from "react";
import styles from "./splash.module.scss";

const Splash = () => {
  const [pwd, setPWD] = useState("");

  const submitForm = (e: FormEvent) => {
    if (!pwd || pwd !== "gotti") alert("Try Harder");
    else alert("GOT EM");
    e.preventDefault();
  };

  return (
    <>
      <form className={styles.funForm} onSubmit={submitForm}>
        <input onChange={(e) => setPWD(e.currentTarget.value)}></input>
        <button formAction="submit" disabled={!pwd}>
          Guess
        </button>
      </form>
    </>
  );
};

export default Splash;

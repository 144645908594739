import React from "react";

type ListProps = {
  items: string[];
};

const MyList = (props: ListProps) => {
  return (
    <ul>
      {props.items.map((v, i) => {
        return (
          <li key={i} style={{ color: "red" }}>
            {v}
          </li>
        );
      })}
    </ul>
  );
};

export default MyList;

export const TodoListStorageKey: string = "TodoLists";
export const SettingsStorageKey: string = "Settings";

export enum RecordStatus {
  Active = 1,
  Inactive = 0,
}

export type TodoList = {
  Id: string;
  RecordStatus: number;
  RecordCreated: Date;
  RecordUpdated: Date;
  Name: string;
  Description: string;
  Items: TodoListItem[];
};

export type TodoListItem = {
  Id: string;
  Name: string;
  IsComplete: boolean;
  Order: number;
};

export type Settings = {
  LastViewedTodoListId: string;
};

export const GetDefaultTodoList = () => {
  return {
    Id: crypto.randomUUID(),
    RecordStatus: RecordStatus.Active,
    RecordCreated: new Date(),
    RecordUpdated: new Date(),
    Name: "My Todo List",
    Description: "",
    Items: [GetDefaultTodoListItem()],
  } as TodoList;
};

export const GetDefaultTodoListItem = () => {
  return {
    Id: crypto.randomUUID(),
    Name: "",
    IsComplete: false,
    Order: 0,
  } as TodoListItem;
};

// export const GetTodoLists = (): ITodoList[] => {
//   return GetStorageItem<ITodoList[]>(TodoListStorageKey);
// };

// export const SaveTodoLists = (lists: ITodoList[]): void => {
//   SaveStorageItem<ITodoList[]>(TodoListStorageKey, lists);
// };

// export const GetSettings = (): ITodoList[] => {
//   return GetStorageItem<ITodoList[]>(SettingsStorageKey);
// };

// export const SaveSettings = (settings: ISettings): void => {
//   SaveStorageItem<ISettings>(SettingsStorageKey, settings);
// };

// export const GetStorageItem = <T>(itemKey: string): T => {
//   const storageItem = localStorage.getItem(itemKey);
//   if (storageItem) {
//     return JSON.parse(storageItem) as T;
//   }

//   return [] as T;
// };

// export const SaveStorageItem = <T>(key: string, item: T): void => {
//   if (!item) return;
//   localStorage.setItem(key, JSON.stringify(item));
// };

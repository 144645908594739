import React from "react";
import styles from "./ttt.module.scss";

export type Squares = Array<string | null>;

type SquareProps = {
  value: string | null;
  onClick: () => void;
};

export const Square = (props: SquareProps) => {
  const { value, onClick } = props;

  return (
    <button className={styles.square} onClick={onClick}>
      {value}
    </button>
  );
};

import { Slider } from "@blueprintjs/core";
import React, { useState } from "react";

const Home = () => {
  const [value, setValue] = useState(0);
  return (
    <>
      <Slider
        min={0}
        max={255}
        value={value}
        onChange={(value) => setValue(value)}
        initialValue={value}
        labelValues={[0, 255]}
      >
        Hello
      </Slider>
      <div>Value: {value}</div>
      <div
        style={{
          width: "100px",
          height: "100px",
          backgroundColor: "#" + value.toString(),
        }}
      ></div>
    </>
  );
};

export default Home;

import React from "react";
import styles from "./todo.module.scss";
import { GetDefaultTodoList, TodoList, TodoListStorageKey } from "./todoUtils";
import TodoListForm from "./TodoListForm";
import { useLocalStorage } from "../hooks/useLocalStorage";

const Todo = () => {
  const [todoLists, setTodoLists] = useLocalStorage<TodoList[]>(
    TodoListStorageKey,
    []
  );

  const SaveTodoList = (item: TodoList): void => {
    const existingIndex = todoLists.findIndex((l) => l.Id === item.Id);
    const updatedList =
      existingIndex === -1
        ? [...todoLists, item]
        : todoLists.map((obj) => (obj.Id === item.Id ? item : obj));
    setTodoLists(updatedList);
  };

  return (
    <div className={styles.mainContainer}>
      <TodoListForm
        todoList={todoLists[0] || GetDefaultTodoList()}
        SaveTodoList={SaveTodoList}
      />
    </div>
  );
};

export default Todo;

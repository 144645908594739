import { useEffect, useState } from "react";

const getSavedValue = <T>(key: string, initialValue: T): T => {
  const savedValue = localStorage.getItem(key);
  if (savedValue) return JSON.parse(savedValue);

  if (initialValue instanceof Function) return initialValue();
  return initialValue;
};

export const useLocalStorage = <T>(
  key: string,
  initialValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [item, setItem] = useState(() => getSavedValue(key, initialValue));

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(item));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return [item, setItem];
};

export default useLocalStorage;

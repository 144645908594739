import React, { useCallback, useState } from "react";
import styles from "./Basics.module.scss";
import Excercise from "./Excercise";
import GreetForm from "./GreetForm";
import MyButton from "./MyButton";
import MyList from "./MyList";

type Joke = {
  id: number;
  setup: string;
  punchline: string;
};

const Basics = () => {
  const [clickCount, setClickCount] = useState(0);
  const arrayData = ["dog", "cat", "chicken", "cow", "sheep", "horse"];
  const jsonArray: Joke[] = [
    {
      id: 1,
      setup: "What's the best thing about a Boolean?",
      punchline: "Even if you're wrong, you're only off by a bit",
    },
    {
      id: 2,
      setup: "Why do programmers wear glasses?",
      punchline: "Because they need to C#",
    },
  ];

  // challenges from: https://coderfiles.dev/blog/reactjs-coding-exercises/

  // challenge #1 - render square
  const renderSqaure = (): JSX.Element => {
    return <div className={styles.hwSquare}>Hello World!</div>;
  };

  // challenge #3 - 3 buttons
  const renderButtons = (): JSX.Element => {
    return (
      <>
        <MyButton buttonNumber={1} />
        <MyButton buttonNumber={2} />
        <MyButton buttonNumber={3} />
      </>
    );
  };

  // challenge #4 - useState
  const renderStateExcercise = useCallback((): JSX.Element => {
    return (
      <>
        <button onClick={() => setClickCount((curr) => curr + 1)}>
          Click Me
        </button>
        <div>Number of times clicked: {clickCount}</div>
      </>
    );
  }, [clickCount]);

  // challenge #5 - declaritive programming using .map
  const renderDeclaritive = (): JSX.Element => {
    return (
      <ul>
        {arrayData.map((v, i) => {
          return <li key={i}>{v}</li>;
        })}
      </ul>
    );
  };

  // challenge #6 - component to list items
  const renderListComponent = (): JSX.Element => {
    return <MyList items={arrayData} />;
  };

  // challenge #7 - greet me form
  const renderGreetForm = (): JSX.Element => {
    return <GreetForm />;
  };

  // challenge #8 - render json array (with styles)
  const renderJsonJokes = (): JSX.Element => {
    return (
      <div>
        {jsonArray.map((j) => {
          return (
            <div key={j.id} className={styles.jokeBox}>
              <h2>{j.setup}</h2>
              <h4>{j.punchline}</h4>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <Excercise number={1} description={"Render 'Hello World' square"}>
        {renderSqaure()}
      </Excercise>
      <Excercise number={3} description={"Passing props"}>
        {renderButtons()}
      </Excercise>
      <Excercise number={4} description={"Using state"}>
        {renderStateExcercise()}
      </Excercise>
      <Excercise number={5} description={"Declaritive programming"}>
        {renderDeclaritive()}
      </Excercise>
      <Excercise number={6} description={"Creating component to list items"}>
        {renderListComponent()}
      </Excercise>
      <Excercise number={7} description={"Creating a react form"}>
        {renderGreetForm()}
      </Excercise>
      <Excercise number={8} description={"Render JSON array with styles"}>
        {renderJsonJokes()}
      </Excercise>
      <div>
        Excercises from: https://coderfiles.dev/blog/reactjs-coding-exercises/
      </div>
    </>
  );
};

export default Basics;

import React from "react";

type ButtonProps = {
  buttonNumber: number;
};

const MyButton = (props: ButtonProps) => {
  const handleButtonClick = (buttonNumber: number) => {
    alert(`Button #${buttonNumber} was clicked!`);
  };

  return (
    <>
      <button onClick={() => handleButtonClick(props.buttonNumber)}>
        Button #{props.buttonNumber}
      </button>
    </>
  );
};

export default MyButton;

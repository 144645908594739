import React, { useEffect, useState } from "react";
import classes from "./Snake.module.scss";

type GridSquare = {
  isHead: boolean;
  isFood: boolean;
};

const Snake = () => {
  // const [grid, setGrid] = useState<GridSquare[][]>(
  //   Array(10).fill(Array(10).fill({}))
  // );
  const grid = Array(10).fill(Array(10).fill({})) as GridSquare[][];
  const [speedCounter, setSpeedCounter] = useState(0);

  const renderGrid = (): React.ReactNode => {
    if (!grid) return null;
    //console.log("GRID: ", grid);
    return grid.map((gs, i) => {
      //console.log("layers: ", gs);
      return gs.map((v, ii) => {
        return (
          <div key={`${i}-${ii}`}>
            {i} - {ii}
          </div>
        );
      });
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setSpeedCounter(speedCounter);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className={classes.snakeGrid}>{renderGrid()}</div>;
};

export default Snake;

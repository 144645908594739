import React from "react";
import styles from "./ttt.module.scss";
import { Square, Squares } from "./square";

type BoardProps = {
  squares: Squares;
  onClick: (squareNumber: number) => void;
};

export const Board = (props: BoardProps) => {
  const renderSquare = (i: number): JSX.Element => {
    return <Square value={props.squares[i]} onClick={() => props.onClick(i)} />;
  };

  // return (
  //   <div>
  //     {chunk(props.squares, 3).map((row, idx) => {
  //       console.log(row);
  //       <div key={idx} className="board-row">
  //         {row.map((col, index) => {
  //           {
  //             renderSquare(index);
  //           }
  //           console.log(col);
  //         })}
  //         ;
  //       </div>;
  //     })}
  //   </div>
  // );

  return (
    <>
      <div className={styles.boardRow}>
        {renderSquare(0)}
        {renderSquare(1)}
        {renderSquare(2)}
      </div>
      <div className={styles.boardRow}>
        {renderSquare(3)}
        {renderSquare(4)}
        {renderSquare(5)}
      </div>
      <div className={styles.boardRow}>
        {renderSquare(6)}
        {renderSquare(7)}
        {renderSquare(8)}
      </div>
    </>
  );
};

import React, { useCallback, useEffect } from "react";
import styles from "./todo.module.scss";
import { GetDefaultTodoListItem, TodoList } from "./todoUtils";
import { useFieldArray, useForm } from "react-hook-form";
import FadeInOut from "../shared/FadeInOut";

type TodoListFormProps = {
  todoList: TodoList;
  SaveTodoList: (item: TodoList) => void;
};

const TodoListForm = (props: TodoListFormProps) => {
  const [canDelete, setCanDelete] = React.useState(false);

  const {
    register,
    handleSubmit,
    //formState: { errors },
    control,
    watch,
  } = useForm<TodoList>({
    defaultValues: props.todoList,
  });

  const { append, remove } = useFieldArray<TodoList>({
    name: "Items",
    control,
  });

  const submitForm = useCallback(
    (data: Partial<TodoList>) => {
      console.log(data);
      const updatedList = Object.assign({}, props.todoList, data);
      props.SaveTodoList(updatedList);
    },
    [props]
  );

  // save on any changes
  useEffect(() => {
    const subscription = watch(() => handleSubmit(submitForm)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, submitForm, watch]);

  return (
    <div className={styles.todoFormContainer}>
      <form className={styles.todoForm} onSubmit={handleSubmit(submitForm)}>
        <div className={styles.formSection}>
          <input
            {...register("Name", {
              // required: "This is required.",
              // minLength: { value: 1, message: "Minimum length is 1." },
            })}
            placeholder="Todo List Name..."
          />
          <button
            type="button"
            className={styles.addBtn}
            onClick={() => {
              append(GetDefaultTodoListItem());
            }}
          >
            <i className="fa fa-plus"></i>
          </button>
          <button
            type="button"
            className={styles.removeBtn}
            onClick={() => {
              setCanDelete(!canDelete);
            }}
          >
            <i className="fa fa-close"></i>
          </button>
        </div>
        {/* <p>{errors.Name?.message}</p> */}
        <div className={styles.formSection}>
          <input {...register("Description")} placeholder="Description..." />
        </div>
        {/* <p>{errors.Description?.message}</p> */}
        <div className={styles.formSection}>
          <div className={styles.todoItems}>
            {watch("Items").map((field, index) => {
              return (
                <div
                  key={field.Id}
                  className={
                    field.IsComplete ? styles.todoItemComplete : styles.todoItem
                  }
                >
                  <input
                    type="checkbox"
                    {...register(`Items.${index}.IsComplete`)}
                  />
                  <input
                    type="text"
                    {...register(`Items.${index}.Name`)}
                    placeholder="New Item..."
                  />
                  <FadeInOut show={canDelete} duration={200}>
                    <button
                      type="button"
                      className={styles.removeBtn}
                      onClick={() => {
                        remove(index);
                      }}
                    >
                      <i className="fa fa-close"></i>
                    </button>
                  </FadeInOut>
                </div>
              );
            })}
          </div>
        </div>
      </form>
    </div>
  );
};

export default TodoListForm;

import React, { useState } from "react";
import styles from "./ttt.module.scss";
import { Board } from "./board";
import { Squares } from "./square";

export const calculateWinner = (squares: Squares) => {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];
  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      return squares[a];
    }
  }
  return null;
};

const TicTacToe = () => {
  const [history, setHistory] = useState([
    { squares: Array(9).fill(null) as Squares },
  ]);
  const [xIsNext, setXIsNext] = useState(true);
  const [step, setStep] = useState(0);
  const squares = history[step].squares;
  const winner = calculateWinner(squares);

  const handleClick = (i: number) => {
    if (!winner && !squares[i]) {
      const newSquares: Squares = squares.slice();
      newSquares[i] = xIsNext ? "X" : "O";
      setHistory(
        history
          .filter((h, index) => index <= step)
          .concat([{ squares: newSquares.slice() }])
      );
      setXIsNext(!xIsNext);
      setStep(step + 1);
      console.log({ history });
    }
  };

  const renderStatus = () => {
    if (winner) return `Winner: ${winner}`;
    else if (squares.filter((s) => !s).length === 0) return "TIE!";
    else return `Next player: ${xIsNext ? "X" : "O"}`;
  };

  const renderMoves = () => {
    return history.map((move, index) => {
      const desc = index ? `Go to move #${index}` : "Go to Game Start";
      return (
        <li key={index}>
          <button onClick={() => jumpTo(index)} disabled={step === index}>
            {desc}
          </button>
        </li>
      );
    });
  };

  const jumpTo = (step: number) => {
    setStep(step);
    setXIsNext(step % 2 === 0);
  };

  return (
    <div className={styles.game}>
      <div className={styles.gameBoard}>
        <Board squares={squares} onClick={handleClick} />
      </div>
      <div className={styles.gameInfo}>
        <div className={styles.status}>{renderStatus()}</div>
        <ol>{renderMoves()}</ol>
      </div>
    </div>
  );
};

export default TicTacToe;

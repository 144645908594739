import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./app.scss";
import Home from "./components/home";
import Layout from "./components/shared/layout";
import NoContent from "./components/shared/noContent";
import TicTacToe from "./components/ttt";
import Basics from "./components/basics";
import Snake from "./components/snake";
import Splash from "./components/splash/splash";
import Todo from "./components/todo";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path="rnd" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="basics" element={<Basics />} />
          <Route path="ttt" element={<TicTacToe />} />
          <Route path="snake" element={<Snake />} />
          <Route path="*" element={<NoContent />} />
        </Route>
        <Route path="todo" element={<Todo />}></Route>
      </Routes>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(<App />);
